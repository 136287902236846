<template>
  <div id="app">
    <NavBar />
    <div class="container mt-3">
      <router-view />
    </div>
  </div>
</template>


<script>
import NavBar from '@/components/NavBar.vue'
export default {
  name: "app",
  components: {
    NavBar
  }
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Sedgwick+Ave&display=swap');

.class-title{
  font-family: 'Sedgwick Ave', cursive;
}
</style>
