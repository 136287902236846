<template>
    <nav class="navbar navbar-expand-lg bg-body-tertiary">
        <div class="container-fluid">
            <router-link to="/paladin" class="navbar-brand">
                <img src="../assets/logo.png" alt="Logo" width="55" height="35" class="d-inline-block align-text-top">
            </router-link>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="navbarNav">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <router-link to="/paladin" class="nav-link active" aria-current="page">Paladin</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/asura" class="nav-link">Asura</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/pyromencer" class="nav-link">Pyromencer</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/priest" class="nav-link">Priest</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link to="/spirit-master" class="nav-link">Spirit Master</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
export default {
    name: 'NavBar',
}
</script>
