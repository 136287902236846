import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/paladin',
    name: 'Paladin',
    component: () => import(/* webpackChunkName: "about" */ '../components/Paladin.vue')
  },
  {
    path: '/asura',
    name: 'Asura',
    component: () => import(/* webpackChunkName: "about" */ '../components/Asura.vue')
  },
  {
    path: '/pyromencer',
    name: 'Pyromencer',
    component: () => import(/* webpackChunkName: "about" */ '../components/Pyromencer.vue')
  },
  {
    path: '/Priest',
    name: 'Priest',
    component: () => import(/* webpackChunkName: "about" */ '../components/Priest.vue')
  },
  {
    path: '/spirit-master',
    name: 'Spirit-master',
    component: () => import(/* webpackChunkName: "about" */ '../components/SpiritMaster.vue')
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
